import Grid from "@material-ui/core/Grid"
import addToMailchimp from "gatsby-plugin-mailchimp"
import * as React from "react"
import { useEffect, useState } from "react"
import Feedback from "../components/Feedback/Feedback"
import Information from "../components/Information/Information"
import Container from "../components/Landing/Landing"
import Questions from "../components/Questions/Questions"
import QuizForm from "../components/QuizForm/QuizForm"
import Seo from "../components/Seo/Seo"
import { FirebaseService } from "../services/firebase.service"
import { MailingService } from "../services/mailing.service"
// @ts-ignore
import { IQuestion } from "../typings"
import { IShineScore, ScoringUtil } from "../utils/scoring.util"

const firebaseSvc = new FirebaseService("spark")

const IndexPage = _ => {
  const [pageNav, setPageNav] = useState<IPageState>("Landing")

  const [user, setUser] = useState<IUserState | undefined>(undefined)
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [result, setResult] = useState(undefined)
  const [answers, setAnswers] = useState<{ [key: string]: string }>({})

  useEffect(() => {
    if (!user || !user.key) {
      return
    }

    const update = { ...user, questions, answers }
    firebaseSvc.updateUserData(update)
  })

  useEffect(() => {
    if (questions.length !== 0) {
      return
    }
    firebaseSvc.getQuestions().then(shuffledQuestions => {
      setQuestions(shuffledQuestions)
      setAnswers(
        shuffledQuestions.reduce((accum, q) => ({ ...accum, [q.key]: "" }), {})
      )
    })
  })

  const addUserToMailChimp = async user => {
    // @ts-ignore
    const mailChimpResponse = await addToMailchimp(user.email, { ...user })
    setResult(mailChimpResponse)
  }

  const handleNavigateNextSection = () => {
    const sections: IPageState[] = [
      "Landing",
      "Information",
      "Questions",
      "FeedBack",
    ]
    setPageNav(currSection => {
      const currIndex = sections.findIndex(section => section === currSection)
      const nextSection = sections[currIndex + 1]

      if (nextSection === "FeedBack") {
        console.log("last in spark");
        const updatedUser = { ...user, questions, answers }
        const score = ScoringUtil.calculateShineScoreDetails(
          updatedUser.questions,
          updatedUser.answers
        )
        const updatedUserWithScore = { ...updatedUser, score }
        setUser(updatedUserWithScore)

        const scoreText = ScoringUtil.getScoreRelatedText(true, score);
        setTimeout(() => {
          handlemail(scoreText);
        }, 80000);
      }
      function handlemail (scoreText){
        MailingService.sendZapierMail({
          from: {
            email: "info@theshinescale.com",
          },
          bcc: [
            { name: "The Shine Scale Team", email: "info@getyourshine.com" },
            { name: "AJ Sarcione", email: "ajsarcione@mac.com" },
          ],
          to: [
            {
              email: user.email,
              name: `${user.firstName} ${user.lastName}`,
            },
          ],
          reply_to: {
            email: "info@getyourshine.com",
            name: "AJ Sarcione @ Get Your Shine",
          },
          dynamicData: scoreText,
        })
          .then(_ => console.log("Email Sent! "))
          .catch(err => console.error("Error occurred", err))

      }
      return nextSection
    })
  }

  const handleFormSubmit = userData => {
    setUser(userData)
    handleNavigateNextSection()
    addUserToMailChimp(userData)
  }

  return (
    <Grid container>
      <Seo
        title="Shine"
        description="The Shine Scale™, created by AJ Sarcione, quizzes heart and mind characteristics to help people and companies accelerate success with shine."
      />
      <Container>
        {pageNav === "Landing" && (
          <QuizForm
            firebaseSvc={firebaseSvc}
            handleFormSubmit={handleFormSubmit}
          />
        )}

        {pageNav === "Information" && (
          <Information handleNavigateNextSection={handleNavigateNextSection} />
        )}

        {pageNav === "Questions" && (
          <Questions
            handleNavigateNextSection={handleNavigateNextSection}
            questions={questions}
            answers={answers}
            setAnswers={setAnswers}
          />
        )}

        {pageNav === "FeedBack" && <Feedback userData={user} />}
      </Container>
    </Grid>
  )
}

export default IndexPage

export type IPageState = "Landing" | "Information" | "Questions" | "FeedBack"

export interface IUserState {
  key?: string
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  questions?: IQuestion[]
  answers?: any
  score?: IShineScore
}